import React from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateValue } from "../redux/UI/DeviceSlice";

const UploadJSON = () => {
  const dispatch = useDispatch();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = JSON.parse(e.target.result);

          // Ensure content is an array (expected format)
          if (!Array.isArray(content)) {
            console.error("Uploaded JSON is not in the correct format.");
            return;
          }

          // Update localStorage with new content
          localStorage.setItem("devices", JSON.stringify(content));

          // Dispatch the action to update Redux state immediately
          dispatch(updateValue(content));
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("json-file-input").click();
  };

  return (
    <>
      <input
        type="file"
        id="json-file-input"
        accept=".json"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <Button
            fullWidth
            sx={{
              bgcolor: "#2cc84d",  
              color: "#FFFFFF",
              fontWeight: "bold",
              borderRadius: 2,
              textTransform: "none",
              py: 1,  
              "&:hover": { bgcolor: "#211747" },  
            }}
        variant="contained"
        onClick={handleButtonClick}
      >
        Upload JSON File
      </Button>
    </>
  );
};

export default UploadJSON;
