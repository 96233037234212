
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset, updateAsync } from "../../redux/beacons/get/beaconOutputSlice";
import { useState } from "react";
import { Typography } from "@mui/material";

export default function GetControls() {
  const beaconIdMapping = {
    "TF1 Home": [
      1, 14, 58, 421, 422, 432, 434, 435, 532, 533, 1191, 1192, 1488,
    ],
    "TF2 Reg": [
      25, 46, 779, 785, 1148, 1149, 1150, 1151, 1157, 1161, 1167, 1168, 1169, 1171, 1172, 1175,
    ],
    "TF4 Checkout": [
      201, 210, 212, 220, 247, 248, 250, 256, 257, 266, 267, 277, 349, 441, 444, 1078, 1080, 1081, 1094, 1097, 1109, 1110, 1144, 1146, 1495, 1496, 1497, 1498, 1499,
    ],
    "TF5 Deals": [
      65, 67, 170, 259, 331, 333, 335, 482, 483, 484, 485, 699, 1136, 1137,
    ],
    "TF6 Menu": [
      58, 197, 201, 205, 208, 397, 631, 633, 638, 641, 645, 655, 662, 690, 697,
    ],
    "TF7 Location": [
      106, 113, 118, 124, 131, 463, 464, 465,
    ],
    "TF8 More": [
      132, 213, 322, 369, 370, 371, 374, 375, 376, 377, 380, 381, 609, 725, 999, 1464, 1466, 1510, 1511, 1512, 1513,
    ],
    "TF9 Loyalty": [
      58, 68, 71, 150, 259, 351, 355, 577, 572, 576, 581, 582, 583, 586, 589, 590, 719, 721, 722, 723, 725, 726, 729, 731, 797, 811, 823, 826, 872, 873, 881, 882, 892, 893, 929, 931, 1041, 1136, 1137, 1183, 1184, 1187, 1189, 1190, 1472, 1473, 1479, 1491, 1492, 1500, 1507, 1508,
    ],
    "TF10 Delivery": [
      201, 416, 417, 441, 458, 609, 830, 837, 1415,
    ],
    "TF11 Suggest": [
      478, 480,
    ],
    "TF12 Store Closed": [
      186, 187,
    ],
    "Full Regression": [
      1, 14, 25, 46, 58, 65, 67, 68, 71, 106, 113, 118, 124, 131, 132, 150, 170, 186, 187, 197, 201, 205, 208, 210, 212, 213, 220, 247, 248, 250, 256, 257, 259, 266, 267, 277, 322, 331, 333, 335, 349, 351, 355, 369, 370, 371, 374, 375, 376, 377, 380, 381, 397, 416, 417, 421, 422, 432, 434, 435, 441, 444, 458, 478, 480, 482, 483, 484, 485, 532, 533, 577, 581, 582, 583, 586, 589, 590, 609, 631, 633, 638, 641, 645, 655, 662, 690, 697, 699, 719, 721, 722, 723, 725, 726, 729, 731, 779, 785, 797, 811, 823, 826, 830, 837, 872, 873, 881, 882, 892, 893, 929, 931, 999, 1041, 1078, 1080, 1081, 1094, 1097, 1109, 1110, 1136, 1137, 1144, 1146, 1148, 1149, 1150, 1151, 1157, 1161, 1167, 1168, 1169, 1171, 1172, 1175, 1183, 1184, 1187, 1189, 1190, 1191, 1192, 1415, 1464, 1466, 1472, 1473, 1479, 1488, 1491, 1492, 1495, 1496, 1497, 1498, 1499, 1500, 1507, 1508, 1510, 1511, 1512, 1513,
    ],
  };

  const dispatch = useDispatch();
  const [flow, setFlow] = useState("");
  const [input, setInput] = useState("");
  const [device, setDevice] = useState("");
  const [startTimeLocal, setStartTimeLocal] = useState(localStorage.getItem("startTimeLocal") || "");
  const [endTimeLocal, setEndTimeLocal] = useState(localStorage.getItem("endTimeLocal") || "");
  const [error, setError] = useState("");
  const devices = JSON.parse(localStorage.getItem("devices")) || [];

  const handleChange = (event) => {
    setFlow(event.target.value);
    setInput(beaconIdMapping[event.target.value].join(", "));
  };

  const navigate = useNavigate();

  const dateHandler = (startOrEnd) => {
    if (startOrEnd === "start") {
      setStartTimeLocal(new Date().toLocaleTimeString());
      setEndTimeLocal("");
      localStorage.setItem("startTimeUnix", Math.floor(Date.now() / 1000));
      localStorage.setItem("startTimeLocal", new Date().toLocaleTimeString());
      localStorage.setItem("endTimeUnix", "");
      localStorage.setItem("endTimeLocal", "");
    } else if (startOrEnd === "end") {
      setEndTimeLocal(new Date().toLocaleTimeString());
      localStorage.setItem("endTimeUnix", Math.floor(Date.now() / 1000));
      localStorage.setItem("endTimeLocal", new Date().toLocaleTimeString());
    } else if (startOrEnd === "clear") {
      setStartTimeLocal("");
      setEndTimeLocal("");
      localStorage.setItem("startTimeUnix", "");
      localStorage.setItem("startTimeLocal", "");
      localStorage.setItem("endTimeUnix", "");
      localStorage.setItem("endTimeLocal", "");
    }
  };

  return (
    <Box sx={{ display: { xs: "none", md: "flex" }, margin: "0 5px" }}>
    <Box sx={{ minWidth: 120, width: "100%" }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Control Panel
      </Typography>
      <Stack spacing={2}>
        <FormControl fullWidth size="small" sx={{ bgcolor: "#FFFFFF", borderRadius: 2, boxShadow: 1 }}>
          <InputLabel id="device-select-label">Device</InputLabel>
          <Select
            labelId="device-select-label"
            id="device-select"
            value={device}
            label="Device"
            onChange={(e) => setDevice(e.target.value)}
            sx={{ borderRadius: 2 }}
          >
            <MenuItem value="None">No Device</MenuItem>
            {devices.map((device) => (
              <MenuItem key={device.id} value={device.deviceID}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        

        <FormControl fullWidth size="small" sx={{ bgcolor: "#FFFFFF", borderRadius: 2, boxShadow: 1 }}>
          <InputLabel id="flow-select-label">Flow</InputLabel>
          <Select
            labelId="flow-select-label"
            id="flow-select"
            value={flow}
            label="Flow"
            onChange={handleChange}
            sx={{ borderRadius: 2 }}
          >
            <MenuItem value={"TF1 Home"}>TF1 Home</MenuItem>
            <MenuItem value={"TF2 Reg"}>TF2 Reg</MenuItem>
            <MenuItem value={"TF4 Checkout"}>TF4 Checkout</MenuItem>
            <MenuItem value={"TF5 Deals"}>TF5 Deals</MenuItem>
            <MenuItem value={"TF6 Menu"}>TF6 Menu</MenuItem>
            <MenuItem value={"TF7 Location"}>TF7 Location</MenuItem>
            <MenuItem value={"TF8 More"}>TF8 More</MenuItem>
            <MenuItem value={"TF9 Loyalty"}>TF9 Loyalty</MenuItem>
            <MenuItem value={"TF10 Delivery"}>TF10 Delivery</MenuItem>
            <MenuItem value={"TF11 Suggest"}>TF11 Suggest</MenuItem>
            <MenuItem value={"TF12 Store Closed"}>TF12 Store Closed</MenuItem>
            <MenuItem value={"Full Regression"}>Full Regression</MenuItem>
            {/* Add other options as needed */}
          </Select>
        </FormControl>

        <TextField
          multiline
          size="small"
          fullWidth
          maxRows={8}
          label="Beacon IDs"
          id="beaconIDs"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{ bgcolor: "#FFFFFF", borderRadius: 2, boxShadow: 1 }}
        />

        <Button
          fullWidth
          variant="contained"
          sx={{
            bgcolor: "#2cc84d",
            color: "#FFFFFF",
            fontWeight: "bold",
            borderRadius: 2,
            textTransform: "none",
            "&:hover": { bgcolor: "#211747" },
          }}
          onClick={() => {
            const hasNumber = /\d/;
            if (input.length <= 0 || !hasNumber.test(input)) {
              setError("You must include at least one beacon ID to submit");
            } else if (startTimeLocal.length > 0 && endTimeLocal.length <= 0) {
              setError("You must end or clear timer before submitting");
            } else {
              setError("");
              dispatch(reset());
              dispatch(updateAsync(input.split(", "), device));
              navigate("/beacons");
            }
          }}
        >
          Submit
        </Button>

        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#211747",
              color: "#FFFFFF",
              fontWeight: "bold",
              borderRadius: 2,
              flex: 1,
              textTransform: 'none',
              "&:hover": { bgcolor: "#27742d" },
            }}
            onClick={() => dateHandler("start")}
          >
            Start
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#211747",
              color: "#FFFFFF",
              fontWeight: "bold",
              borderRadius: 2,
              flex: 1,
              textTransform: 'none',
              "&:hover": { bgcolor: "#DA291C" },
            }}
            onClick={() => {
              if (startTimeLocal.length > 0) {
                dateHandler("end");
              }
            }}
          >
            End
          </Button>
        </Stack>

        <Box display="flex">
          <Typography align="center" sx={{ width: "50%" }}>
            {startTimeLocal}
          </Typography>
          <Typography align="center" sx={{ width: "50%"}}>
            {endTimeLocal}
          </Typography>
        </Box>

        {startTimeLocal && (
          <Button
            fullWidth
            variant="outlined"
            sx={{
              color: "#D32F2F",
              borderColor: "#D32F2F",
              fontWeight: "bold",
              borderRadius: 2,
              "&:hover": { bgcolor: "#FFEBEE", borderColor: "#D32F2F" },
            }}
            onClick={() => dateHandler("clear")}
          >
            Clear Timer
          </Button>
        )}

        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </Stack>
    </Box>
    </Box>
  );
}
