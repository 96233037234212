import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useState, useEffect, memo } from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import _ from "lodash";

function compareStaticFields(obj1, obj2) {
  return _.every(obj1, (obj1Value, key) => {
    if (obj1Value) {
      if (obj1Value.fieldType && obj1Value.fieldType === "Static") {
        return _.isEqual(obj1Value.value, obj2[key]);
      }
    }
    return true;
  });
}

const CustomToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "0px solid #E0E0E0",
  borderRadius: "8px",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F5F5F5",
    color: "#333333",
    fontWeight: "bold",
    borderBottom: "0px solid #E0E0E0",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "0px solid #E0E0E0",
    padding: "8px 16px",
  },
  "& .MuiDataGrid-row": {
    minHeight: "60px",
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "0px solid #E0E0E0",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: "50%",
    margin: "0 4px",
  },
}));

const columns = [
  {
    field: "variableName",
    headerName: "Variable Name",
    flex: 0.5,
    cellClassName: "word-wrap",
  },
  {
    field: "expectedValue",
    headerName: "Expected Value",
    flex: 1,
    cellClassName: "word-wrap",
  },
  {
    field: "actualValue",
    headerName: "Actual Value",
    flex: 1,
    cellClassName: "word-wrap",
  },
  {
    field: "passFail",
    headerName: "Status",
    flex: 0.2,
    renderCell: (params) => {
      if (params.value === "Pass") {
        return (
          <Box
            sx={{
              display: "flex",
              height: "100%",
            }}
          >
            <img
              src="/pass.png"
              alt="Pass"
              style={{ width: "30px", height: "30px" }}
            />
          </Box>
        );
      } else {
        return (
          <Box
            sx={{
              display: "flex",
              height: "100%",
            }}
          >
            <img
              src="/fail.png"
              alt="Fail"
              style={{ width: "30px", height: "30px" }}
            />
          </Box>
        );
      }
    },
    valueGetter: (params) => params,
  },
  { field: "fieldType", headerName: "Type", flex: 0.2 },
];

function BeaconAccordion(props) {
  const [cardData, setCardData] = useState({});
  const {onRendered} = props

  let tableData = [];
  let tableIndex = 0;

  for (const [key, value] of Object.entries(props.apiDataTrue.data).filter(
    ([key]) => key !== "market"
  )) {
    let truthValue = value instanceof Object ? value.value : value;
    let truthFieldType = value instanceof Object ? value.fieldType : "N/A";
    const isPassOrFail = () => {
      if (
        truthValue !== props.apiData.data[key] &&
        truthFieldType !== "Dynamic" &&
        props.apiData.data[key] !== null
      ) {
        return "Fail";
      } else {
        return "Pass";
      }
    };

    tableData = [
      ...tableData,
      {
        id: tableIndex,
        variableName: key,
        expectedValue: truthValue,
        actualValue: props.apiData.data[key],
        passFail: isPassOrFail(),
        fieldType: truthFieldType,
      },
    ];
    tableIndex++;
  }

  useEffect(() => {
    if (onRendered) {
     onRendered(); // Notify OutputHandler when this component has rendered
    }
}, [onRendered]);

  useEffect(() => {
    const trueAPIURL = new URL(props.apiDataTrue.config.url);
    if (props.apiDataTrue.status === 404 || props.apiData.status === 404) {
      setCardData({
        beacon: trueAPIURL.searchParams.get("beacon_id"),
        status: "Missing",
      });
    } else if (
      props.apiDataTrue.status === 200 &&
      props.apiData.status === 200
    ) {
      if (compareStaticFields(props.apiDataTrue.data, props.apiData.data)) {
        setCardData({
          beacon: trueAPIURL.searchParams.get("beacon_id"),
          status: "Pass",
        });
      } else {
        setCardData({
          beacon: trueAPIURL.searchParams.get("beacon_id"),
          status: "Fail",
        });
      }
    }
  }, [props.apiDataTrue, props.apiData]);


  const getStatusColor = (status) => {
    switch (status) {
      case "Pass":
        return "success";
      case "Missing":
        return "warning";
      case "Fail":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <>
      {cardData.status ? (
        <Accordion
          sx={{
            width: "100%",
            marginBottom: "10px",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mr: 5,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Beacon {cardData.beacon}&nbsp;
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  label={cardData.status}
                  color={getStatusColor(cardData.status)}
                  sx={{
                    fontWeight: "bold",
                    borderRadius: "8px",
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Poppins, sans-serif",
                  }}
                />
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {props.apiDataTrue.status === 200 &&
            props.apiData.status === 200 ? (
              <Box
                sx={{
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <StyledDataGrid
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.passFail}`
                  }
                  transitionProp={{ timeout: 1 }}
                  slots={{ toolbar: CustomToolBar }}
                  getRowHeight={() => "auto"}
                  autoHeight
                  disableColumnResize
                  rows={tableData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 50 },
                    },
                    filter: {
                      filterModel: {
                        items: [
                          {
                            field: "fieldType",
                            operator: "equals",
                            value: "Static",
                          },
                        ],
                      },
                    },
                  }}
                  pageSizeOptions={[50, 100]}
                />
              </Box>
            ) : (
              <span>Data for Beacon {cardData.beacon} is missing.</span>
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}
    </>
  );
}

export default memo(BeaconAccordion, (prevProps, nextProps) => {
  return (
      _.isEqual(prevProps.apiData, nextProps.apiData) &&
      _.isEqual(prevProps.apiDataTrue, nextProps.apiDataTrue)
  );
});