import './App.css';
import PermanentFrame from './components/UI/PermanentFrame';
import { useStytchUser } from '@stytch/react';
import Login from "./components/users/Login";

function App() {
  // The useStytchUser hook will return the existing Stytch User object if one exists
  // const value = useSelector(RegionModal)
  const { user }  = useStytchUser();
  
  return (<>
    {user ? <PermanentFrame /> : <Login />}
    {/* <PermanentFrame /> */}
    </>
  );
}

export default App;
