import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { marketValue } from '../../redux/UI/MarketModalSlice';
import { useSelector } from 'react-redux';

// Market data with flag paths
const marketTable = {
  'AU': { name: "Australia", flag: "/flags/australia.png" },
  'DE': { name: "Germany", flag: "/flags/germany.png" },
  'UK': { name: "UK", flag: "/flags/uk.png" },
  'US': { name: "US", flag: "/flags/usa.png" },
  'CA': { name: "Canada", flag: "/flags/canada.png" },
  'Playground': { name: "Playground", flag: "/flags/playground.png" },
};

export default function MarketName() {
  // Retrieve current market from localStorage or Redux (adjust as needed)

  const marketStore = useSelector(marketValue)
  let market = marketStore
  if(localStorage.getItem("market") !== null){
    market = JSON.parse(localStorage.getItem("market"))
  }


  if(market.code !== null && market.code !== undefined){
    const marketInfo = marketTable[market.code]
    return (
      <Box 
        sx={{
          p: 0.5,
          mt: 2,
          alignSelf: 'center'
        }}
      >
        {/* Heading for "Current Market" */}
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          Current Market
        </Typography>
  
        {/* Centered Country Name and Flag Icon */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
          <Typography variant="body1">
            {marketInfo.name}
          </Typography>
          <img
            src={marketInfo.flag}
            alt={`${marketInfo.name} flag`}
            style={{ width: 30, height: 30, marginLeft: 8 }}
          />
        </Box>
      </Box>
    );
  }
  
  }
