// DeviceSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialDevices = () => {
  const devices = localStorage.getItem("devices");
  try {
    return devices ? JSON.parse(devices) : [];
  } catch (error) {
    console.error("Error parsing devices from localStorage:", error);
    return [];
  }
};

export const deviceSlice = createSlice({
  name: "deviceSlice",
  initialState: initialDevices(),
  reducers: {
    updateValue: (state, action) => {
      return action.payload; // Replace the state entirely with new payload
    },
  },
});

export const { updateValue } = deviceSlice.actions;

export const deviceSliceValue = (state) => state.deviceSlice;
export default deviceSlice.reducer;