import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  openValue,
  updateOpen,
  updateValue,
} from "../../redux/UI/MarketModalSlice";
import { useSelector, useDispatch } from "react-redux";

// Styles for modal box
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

// Market data with image paths
const marketTable = {
  'AU': { name: "Australia", flag: "/flags/australia.png" },
  'DE': { name: "Germany", flag: "/flags/germany.png" },
  'UK': { name: "UK", flag: "/flags/uk.png" },
  'US': { name: "USA", flag: "/flags/usa.png" },
  'CA': { name: "Canada", flag: "/flags/canada.png" },
  'Playground': { name: "Playground", flag: "/flags/playground.png" },
};

export default function MarketModal() {
  const dispatch = useDispatch();
  const [market, setMarket] = React.useState(
    localStorage.getItem("market") === null
      ? ""
      : JSON.parse(localStorage.getItem("market"))
  );
  const modalOpen = useSelector(openValue);

  const handleChange = (event) => {
    const selectedMarket = event.target.value;
    setMarket({ value: marketTable[selectedMarket].name, code: selectedMarket });
  };

  const localStorageSetter = (market) => {
    localStorage.setItem("market", JSON.stringify(market));
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Header */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" fontWeight="bold">
              Market Selection
            </Typography>
          </Box>

          {/* Description */}
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            You must select a market before continuing.
          </Typography>

          {/* Market Selection Dropdown */}
          <FormControl fullWidth>
            <InputLabel id="market-select-label">Market</InputLabel>
            <Select
              labelId="market-select-label"
              id="market-select"
              value={market.code || ""}
              label="Market"
              onChange={handleChange}
              sx={{
                bgcolor: "#f7f7f7",
                borderRadius: 3,
              }}
            >
              {Object.keys(marketTable).map((key) => (
                <MenuItem key={key} value={key}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={marketTable[key].flag}
                      alt={`${marketTable[key].name} flag`}
                      style={{ width: 30, height: 30, marginRight: 8 }}
                    />
                    {marketTable[key].name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Action Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              disabled={!localStorage.getItem("market")}
              variant="outlined"
              sx={{
                borderColor: "#ddd",
                color: "#DA291C",
                textTransform: "none",
                fontWeight: "bold",
                "&:hover": { borderColor: "#bbb" },
              }}
              onClick={() => dispatch(updateOpen(false))}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!market.code}
              sx={{
                bgcolor: "#2cc84d",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                "&:hover": { bgcolor: "#211747" },
              }}
              onClick={() => {
                localStorageSetter(market);
                dispatch(updateValue(market));
                dispatch(updateOpen(false));
              }}
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
