import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import GetControls from "./GetControls";
import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import UpdateControls from "./UpdateControls";
import CreateControls from "./CreateControls";
import Button from "@mui/material/Button";
import MarketModal from "./MarketModal";
import { useDispatch } from "react-redux";
import { updateOpen } from "../../redux/UI/MarketModalSlice";
import { useStytch } from "@stytch/react";
import MarketName from "./MarketName";
import { useCallback } from "react";
import CreateDevices from "./DeviceHandler";
import HomeIcon from "@mui/icons-material/Home";
import UpdateIcon from "@mui/icons-material/Update";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DevicesIcon from "@mui/icons-material/Devices";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { marketValue } from "../../redux/UI/MarketModalSlice";

// Define the flag paths for each market
const marketTable = {
  AU: "/flags/australia.png",
  DE: "/flags/germany.png",
  UK: "/flags/uk.png",
  US: "/flags/usa.png",
  CA: "/flags/canada.png",
  Playground: "/flags/playground.png",
};

const drawerWidth = 240;

function PermanentFrame() {
  const dispatch = useDispatch();
  const location = useLocation();

  // Retrieve current market from localStorage
  const marketStore = useSelector(marketValue)
  let currentMarket = marketStore
  if(localStorage.getItem("market") !==null){
    currentMarket = JSON.parse(localStorage.getItem("market"))
  }
  const selectedMarketFlag = marketTable[currentMarket.code]

  const selectedColor = "#2cc84d";
  const defaultColor = "#A9A9A9";

  const LogoutButton = () => {
    const stytchClient = useStytch();
    const logout = useCallback(() => {
      stytchClient.session.revoke();
    }, [stytchClient]);
    return (
      <Button
        sx={{ color: "#333", fontWeight: "bold", display: "flex", alignItems: "center" }}
        onClick={logout}
      >
        <LogoutIcon fontSize="small" sx={{ color: defaultColor, mr: 1 }} />
        Logout
      </Button>
    );
  };

  if (localStorage.getItem("market") === null) {
    dispatch(updateOpen(true));
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          backgroundColor: "#F8F9FA",
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            backgroundColor: "#FFFFFF",
            color: "#333",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Toolbar sx={{ justifyContent: "flex-end", pr: 3 }}>
            <Button
              component={Link}
              to="/"
              sx={{ color: "#333", fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <HomeIcon fontSize="small" sx={{ color: location.pathname === "/" ? selectedColor : defaultColor, mr: 1 }} />
              Home
            </Button>
            <Button
              component={Link}
              to="/update"
              sx={{ color: "#333", fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <UpdateIcon fontSize="small" sx={{ color: location.pathname === "/update" ? selectedColor : defaultColor, mr: 1 }} />
              Update
            </Button>
            <Button
              component={Link}
              to="/create"
              sx={{ color: "#333", fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <AddCircleIcon fontSize="small" sx={{ color: location.pathname === "/create" ? selectedColor : defaultColor, mr: 1 }} />
              Create
            </Button>
            <Button
              sx={{ color: "#333", fontWeight: "bold", display: "flex", alignItems: "center" }}
              onClick={() => dispatch(updateOpen(true))}
            >
              <img
                src={selectedMarketFlag}
                alt={`${currentMarket.code} flag`}
                style={{ width: 20, height: 20, marginRight: 8, objectFit: "cover", borderRadius: "2px" }}
              />
              Set Market
            </Button>
            <MarketModal />
            <Button
              component={Link}
              to="/devices"
              sx={{ color: "#333", fontWeight: "bold", display: "flex", alignItems: "center" }}
            >
              <DevicesIcon fontSize="small" sx={{ color: location.pathname === "/devices" ? selectedColor : defaultColor, mr: 1 }} />
              Devices
            </Button>
            <LogoutButton />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#FFFFFF",
              padding: "10px",
              borderRight: "1px solid #E0E0E0",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Link to="/">
            <Box sx={{ m: 2 }}>
              <img
                style={{ width: "100%", borderRadius: "8px" }}
                alt="Brillio Logo"
                src="/Brillio-Bright-Logo.png"
              />
            </Box>
          </Link>
          <MarketName />
          <Toolbar />
          {location.pathname.includes("update") ? <UpdateControls /> : ""}
          {location.pathname.includes("create") ? <CreateControls /> : ""}
          {location.pathname.includes("devices") ? <CreateDevices /> : ""}
          {!location.pathname.includes("update") &&
          !location.pathname.includes("create") &&
          !location.pathname.includes("devices") ? (
            <GetControls />
          ) : (
            ""
          )}
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "#F8F9FA", p: 3 }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default PermanentFrame;
