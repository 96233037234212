import { createSlice } from "@reduxjs/toolkit";

export const inputSlice = createSlice({
    name: "input",
    initialState: {
        value: []
    },
    reducers: {
        update: (state, action) => {
            state.value = action.payload
        }
    }
})

export const {update} = inputSlice.actions

export const inputValue = (state) => state.input.value
export default inputSlice.reducer