import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import OutputHandler from "./components/beacons/get/OutputHandler";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import TokenAuthenticator from "./components/users/TokenAuthenticator";
import BeaconUpdateContainer from "./components/beacons/update/BeaconUpdateContainer";
import BeaconCreateContainer from "./components/beacons/create/BeaconCreateContainer";
import AddDeviceContainer from "./components/users/DeviceList";
import {reset as updateReset} from "./redux/beacons/update/getOneBeacon"
import {reset as createReset} from "./redux/beacons/create/createBeacon"

// We initialize the Stytch client using our project's public token which can be found in the Stytch dashboard
// optional object for configuring SDK cookie behavior, currently showing defaults
const stytchOptions = {
  cookieOptions: {
    opaqueTokenCookieName: "stytch_session",
    jwtCookieName: "stytch_session_jwt",
    path: "/",
    availableToSubdomains: false,
    domain: "",
  }
}

const stytchClient = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN, stytchOptions);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "beacons/",
        element: <OutputHandler />,
      },
      {
        path: "update/",
        element: <BeaconUpdateContainer />,
        loader: () => {
          store.dispatch(updateReset())
          return 0
        }
      },
      {
        path: "create/",
        element: <BeaconCreateContainer />,
        loader: () => {
          store.dispatch(createReset())
          store.dispatch(updateReset())
          return 0
        }
      },
      {
        path: "devices/",
        element: <AddDeviceContainer />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <StytchProvider stytch={stytchClient}>
        <TokenAuthenticator>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </TokenAuthenticator>
      </StytchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
