import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const beaconOutputSlice = createSlice({
  name: "beaconOutput",
  initialState: {
    value: [],
    loading: true,
  },
  reducers: {
    updateOutput: (state, action) => {
      state.loading = false;
      state.value = action.payload.map(item => ({
        beaconID: item.beaconID,
        truth: item.truth || { data: {}, status: 404 }, // Provide default data structure
        beaconData: item.beaconData || { data: {}, status: 404 }, // Provide default data structure
    }));
    },
    reset: (state) => {
      state.value = [];
      state.loading = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
  },
  },
});

export const { updateOutput, reset, setLoading  } = beaconOutputSlice.actions;

export const updateAsync = (list, deviceID) => async (dispatch) => {
  dispatch(setLoading(true))
  dispatch(reset());
  const marketCode = JSON.parse(localStorage.getItem("market")).code;
  const responses = [];

  const requests = list.map((beaconID, index) => {
      const truthURL = `https://mcdapi.cxsandbox.com/truth-inbound?beacon_id=${beaconID}&market=${marketCode}`;
      let beaconURL = `https://mcdapi.cxsandbox.com/beacon?beacon_id=${beaconID}&market=${marketCode}`;

      if (localStorage.getItem("startTimeUnix") && localStorage.getItem("startTimeUnix").length > 0) {
          beaconURL += `&startTime=${localStorage.getItem("startTimeUnix")}&endTime=${localStorage.getItem("endTimeUnix")}`;
      }
      if (deviceID && deviceID !== "None" && deviceID !== "") {
          beaconURL += `&deviceID=${deviceID}`;
      }

      return axios
          .all([axios.get(truthURL), axios.get(beaconURL)])
          .then(axios.spread((res1, res2) => {
              responses.push({
                  id: index,
                  beaconID: beaconID,
                  truth: { data: res1.data, config: { url: res1.config.url }, status: res1.status },
                  beaconData: { data: res2.data, config: { url: res2.config.url }, status: res2.status },
              });
          }))
          .catch((err) => {
              responses.push({
                  id: index,
                  beaconID: beaconID,
                  truth: { data: {}, config: { url: err.response.config.url }, status: 404 }, // Default empty data on error
                  beaconData: { data: {},config: { url: err.response.config.url }, status: 404 }, // Default empty data on error
              });
          });
  });

  await Promise.all(requests);
  console.log('beaconOutputSlice Requests: ', requests)
  dispatch(updateOutput(responses));
  dispatch(setLoading(false));
};



export const outputValue = (state) => state.beaconOutput.value;
export const outputLoading = (state) => state.beaconOutput.loading;
export default beaconOutputSlice.reducer;
