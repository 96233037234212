import React from "react";
import Button from "@mui/material/Button";

function DownloadJSON({fileName }) {
  const downloadJSON = () => {
    const data = JSON.parse(localStorage.getItem("devices"));
    console.log(data)
    const jsonData = JSON.stringify(data);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.json`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
            fullWidth
            sx={{
              bgcolor: "#2cc84d", 
              color: "#FFFFFF",
              fontWeight: "bold",
              borderRadius: 2,
              textTransform: "none",
              py: 1, 
              "&:hover": { bgcolor: "#211747" },
            }}
      variant="contained"
      onClick={downloadJSON}
    >
      Download JSON
    </Button>
  );
}

export default DownloadJSON;
