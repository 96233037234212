import { createSlice } from "@reduxjs/toolkit";

export const marketModalSlice = createSlice({
    name: "market",
    initialState: {
        open: false,
        value: {},
    },
    reducers: {
        updateOpen: (state, action) => {
            state.open = action.payload
        },
        updateValue: (state, action) => {
            state.value = {...action.payload}
        }
    }
})

export const {updateOpen, updateValue} = marketModalSlice.actions

export const openValue = (state) => state.marketModal.open
export const marketValue = (state) => state.marketModal.value
export default marketModalSlice.reducer