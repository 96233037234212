import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  outputValue,
  outputLoading,
} from "../../../redux/beacons/get/beaconOutputSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import BeaconAccordion from "./BeaconAccordion";
import Pagination from "@mui/material/Pagination";
import { keyframes } from "@emotion/react";

const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export default function OutputHandler() {
  const apiReturn = useSelector(outputValue);
  const loading = useSelector(outputLoading);
  const [renderingComplete, setRenderingComplete] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const renderedCount = useRef(0);
  const spinnerTimerRef = useRef(null);
  const itemsPerPage = 25;
  const minimumLoadTime = 0; // Set to 0 as per current preference

  const sortedApiReturn = [...apiReturn].sort(
    (a, b) => a.beaconID - b.beaconID
  );
  const totalItems = sortedApiReturn.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedData = sortedApiReturn.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleAccordionRendered = () => {
    renderedCount.current += 1;
    if (renderedCount.current === paginatedData.length) {
      setRenderingComplete(true);
      setShowSpinner(false); // Hide spinner when rendering completes
    }
  };

  useEffect(() => {
    // Toggle overflow on the body based on spinner visibility
    document.body.style.overflow = showSpinner ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto"; // Reset overflow on cleanup
    };
  }, [showSpinner]);

  useEffect(() => {
    if (loading) {
      setRenderingComplete(false);
      renderedCount.current = 0;
      setCurrentPage(1);
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }

    return () => clearTimeout(spinnerTimerRef.current);
  }, [loading]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    renderedCount.current = 0;
    setRenderingComplete(false);
    setShowSpinner(true);
  };

  return (
    <>
      {showSpinner && !renderingComplete && (
        <Box
          sx={{
            display: "flex",
            placeContent: "center",
            marginTop: "20%",
            width: "100%",
            zIndex: 10,
            animation: `${spinAnimation} 1.5s linear infinite`,
          }}
        >
          <CircularProgress
            size="6rem"
            variant="determinate"
            value={70}
            sx={{
              color: "#4caf50",
            }}
          />
        </Box>
      )}
      <div
        id="cardContainer"
        style={{ opacity: loading || !renderingComplete ? 0.5 : 1 }}
      >
        {paginatedData.map((obj, index) => (
          <BeaconAccordion
            key={obj.beaconID}
            apiDataTrue={obj.truth}
            apiData={obj.beaconData}
            onRendered={handleAccordionRendered}
          />
        ))}
      </div>
      {totalItems > itemsPerPage && renderingComplete && (
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingBottom: 2 }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#4caf50 !important", // Green background for selected item
                color: "white", // Keep the text color on the selected item as white
              },
            }}
          />
        </Box>
      )}
      <Outlet />
    </>
  );
}
