import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getOneBeaconAsync } from "./getOneBeacon";

export const updateOneBeaconSlice = createSlice({
  name: "updateBeacon",
  initialState: {
    value: {},
    response: {},
  },
  reducers: {
    updateOneBeaconData: (state, action) => {
      state.value = action.payload;
    },
    reset: (state) => {
      state.value = {};
      state.response = {};
    },
    putResponse: (state, action) => {
      state.response = action.payload;
    },
  },
});

export const { updateOneBeaconData, reset, putResponse } = updateOneBeaconSlice.actions;

export const putResponseAsync = (beacon, data) => async (dispatch) => {
  dispatch(reset());
  const marketCode = JSON.parse(localStorage.getItem("market")).code;
  const dataWithMarket = { ...data, market: marketCode };

  try {
    const res = await axios.put(
      `https://mcdapi.cxsandbox.com/truth-inbound?beacon_id=${beacon}&market=${marketCode}`,
      dataWithMarket
    );
    // Dispatch only the response data to avoid non-serializable values
    dispatch(putResponse(res.data));
    dispatch(getOneBeaconAsync(beacon));
  } catch (err) {
    // Handle errors
    console.error(err);
  }
};

export const updateOneBeaconSliceValue = (state) => state.updateOneBeacon.value;
export default updateOneBeaconSlice.reducer;
