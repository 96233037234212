import { configureStore } from '@reduxjs/toolkit'
import beaconInputReducer from "./beacons/get/beaconInputSlice"
import beaconOutputReducer from "./beacons/get/beaconOutputSlice"
import getOneBeaconReducer from './beacons/update/getOneBeacon'
import inputReducer from "./UI/InputSlice"
import updateOneBeaconSliceReducer from "./beacons/update/updateBeacon"
import createBeaconReducer from "./beacons/create/createBeacon"
import marketModalReducer from './UI/MarketModalSlice'
import deviceSliceReducer from './UI/DeviceSlice'


export default configureStore({
  reducer: {
    beacons: beaconInputReducer,
    beaconOutput: beaconOutputReducer,
    input: inputReducer,
    getOneBeacon: getOneBeaconReducer,
    updateOneBeacon: updateOneBeaconSliceReducer,
    createBeacon: createBeaconReducer,
    marketModal: marketModalReducer, 
    deviceSlice: deviceSliceReducer,
  },
})