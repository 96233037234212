import React, { useEffect, useState } from "react";
import { useStytch } from '@stytch/react';
import { TextField, Typography, Button, Box, CssBaseline, Container } from '@mui/material';

const Login = () => {
    const [error, setError] = useState();
    const [showNextStep, setShowNextStep] = useState(false);
    const stytchClient = useStytch();

    const callbacks = {
        onEvent: ({ type, data }) => {
            if (type === 'MAGIC_LINK_LOGIN_OR_CREATE') {
                console.log('Email magic Link sent', data);
            }
        },
        onError: (data) => {
            console.log(data);
        },
    };

    const errorStyle = {
        display: 'block',
        textAlign: 'center',
        color: '#CC0000',
        fontWeight: 'bold'
    };

    const sendEmailMagicLink = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        stytchClient.magicLinks.email.send(data.get('email'), {
            login_magic_link_url: process.env.REACT_APP_LOGIN_REDIRECT || 'http://localhost:3000/',
            login_expiration_minutes: 5,
        }).then(() => {
            setError(null);
            setShowNextStep(true);
        }, () => {
            setError('Invalid Email');
        });
    };

    const LoginForm = () => {
        return (
            <>
                <Typography component="h1" variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                    QA Tool Sign in
                </Typography>
                {error ? <label style={errorStyle}>{error}</label> : null} 

                <Box component="form" onSubmit={sendEmailMagicLink} noValidate sx={{ mt: 1, width: "100%" }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        placeholder="example@email.com"
                        autoFocus
                        sx={{ bgcolor: "#FFFFFF", borderRadius: 2, boxShadow: 1 }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 2,
                            bgcolor: "#2cc84d",
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            borderRadius: 2,
                            textTransform: "none",
                            "&:hover": { bgcolor: "#211747" },
                        }}
                    >
                        Continue with email
                    </Button>
                </Box>
            </>
        );
    };

    const NextStepComponent = () => {
        return (
            <Box component="div" sx={{ textAlign: "center" }}>
                <Typography component="h3" variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
                    Check your email for your login link
                </Typography>
            </Box>
        )
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 3,
                        bgcolor: "#F9FAFB",
                        borderRadius: 2,
                        boxShadow: 1,
                        width: "100%"
                    }}
                >
                    <img src="https://mcd.cxsandbox.com/Brillio-Bright-Logo.png" alt="Brillio" style={{ mixBlendMode: 'multiply', mb: 3 }} />
                    {showNextStep === false ? <LoginForm /> : <NextStepComponent />}
                </Box>
            </Container>
        </>
    );
};

export default Login;
