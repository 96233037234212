import {createSlice} from '@reduxjs/toolkit'

export const beaconInputSlice = createSlice({
    name: 'beaconInput',
    initialState: {
        value: [],
    },
    reducers: {
        update: (state, action) => {
            state.value = action.payload.split(", ")
            
        }
    }
})

export const { update } = beaconInputSlice.actions


export const updateAsync = (list) => (dispatch) => {
    setTimeout(() => {
        dispatch(update(list))
    }, 1000)
}

export const inputValue = (state) => state.beacons.value
export default beaconInputSlice.reducer