import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import DownloadJSON from "../../utilities/DownloadJSON";
import UploadJSON from "../../utilities/UploadJSON";
import { Typography, Button } from "@mui/material";

export default function CreateDevices() {
  return (
    <Box
      sx={{
        p: 0.5,
        width: "100%",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Device Management
      </Typography>
      <Stack spacing={2}>
        <Box sx={{ bgcolor: "#FFFFFF",}}>
          <DownloadJSON fileName="qa-tool-device-list"/>
        </Box>

        <Box sx={{ bgcolor: "#FFFFFF", }}>
          <UploadJSON/>
        </Box>
      </Stack>
    </Box>
  );
}
