import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { getOneBeaconAsync } from "../../redux/beacons/update/getOneBeacon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

const marketTable = {
  AU: { name: "Australia" },
  DE: { name: "Germany" },
  UK: { name: "UK" },
  US: { name: "USA" },
  CA: { name: "Canada" },
  Playground: { name: "Playground" },
};

export default function BeaconMarketModal({ open, onClose }) {
  const dispatch = useDispatch();
  const [beaconID, setBeaconID] = useState("");
  const [marketCode, setMarketCode] = useState("");

  const handleFetchBeacon = () => {
    if (beaconID && marketCode) {
      dispatch(getOneBeaconAsync(beaconID, marketCode));
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" fontWeight="bold">
          Add Beacon and Market
        </Typography>
        <TextField
          label="Beacon ID"
          value={beaconID}
          onChange={(e) => setBeaconID(e.target.value)}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="market-select-label">Market</InputLabel>
          <Select
            labelId="market-select-label"
            value={marketCode}
            label="Market"
            onChange={(e) => setMarketCode(e.target.value)}
          >
            {Object.keys(marketTable).map((key) => (
              <MenuItem key={key} value={key}>
                {marketTable[key].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleFetchBeacon}
          disabled={!beaconID || !marketCode}
          sx={{ backgroundColor: "#4caf50", "&:hover": { bgcolor: "#211747" } }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
}
