import React, { useState } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

export default function AddRowModal({ open, handleClose, handleAddRow }) {
  const [newRow, setNewRow] = useState({
    variableName: "",
    value: "",
    fieldType: "Static",
  });

  const canSubmit =
    newRow.variableName && newRow.value && newRow.fieldType;

  const handleInputChange = (field, value) => {
    setNewRow((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    if (canSubmit) {
      handleAddRow(newRow);
      setNewRow({ variableName: "", value: "", fieldType: "Static" });
      handleClose();
    }
  };

  const handleManualClose = () => {
    handleClose(); // Allow the user to close freely without checking the fields.
  };

  return (
    <Modal open={open} onClose={handleManualClose}>
      <Box sx={style}>
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          Add New Row
        </Typography>
        <TextField
          label="Variable Name"
          fullWidth
          value={newRow.variableName}
          onChange={(e) => handleInputChange("variableName", e.target.value)}
          sx={{ mb: 2, bgcolor: "#f7f7f7", borderRadius: 3 }}
        />
        <TextField
          label="Value"
          fullWidth
          value={newRow.value}
          onChange={(e) => handleInputChange("value", e.target.value)}
          sx={{ mb: 2, bgcolor: "#f7f7f7", borderRadius: 3 }}
        />
        <TextField
          select
          label="Field Type"
          fullWidth
          value={newRow.fieldType}
          onChange={(e) => handleInputChange("fieldType", e.target.value)}
          SelectProps={{ native: true }}
          sx={{ mb: 2, bgcolor: "#f7f7f7", borderRadius: 3 }}
        >
          <option value="Static">Static</option>
          <option value="Dynamic">Dynamic</option>
        </TextField>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleManualClose} variant="outlined"               sx={{
                borderColor: "#ddd",
                color: "#DA291C",
                textTransform: "none",
                fontWeight: "bold",
                "&:hover": { borderColor: "#bbb" },
              }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!canSubmit}
            variant="contained"
            sx={{
                bgcolor: "#2cc84d",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                "&:hover": { bgcolor: "#211747" },
              }}
          >
            Add Row
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
